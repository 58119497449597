<!--黄河流域-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col >
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col >
        <el-row class="contentDiv">
          <div class="yellowRiverBackground flexContainer">
            <div v-for="(item, index) in areaList" :key="index"
                 :class="item.class" @click="changeActiveIndex(item, index)">
              <div class="imgContainer flexACenterJCenter">
                <img src="@/assets/img/giCulture/yellowIcon.png" alt="" v-show="activeIndex !== index"
                     class="yellowIcon">
                <img src="@/assets/img/giCulture/blueIcon.png" alt="" v-show="activeIndex === index"
                     class="blueIcon">
              </div>
              <span class="areaDot onlyFontClass">{{item.name}}</span>
            </div>
          </div>
        </el-row>
        <div class="bottomRow">
          <div class="flexAlignItemCenter">
            <img :src="provinceImgUrl" alt="" class="giProvinceImgClass">
            <span class="giProvinceName">{{provinceName}}</span>
          </div>
          <div class="w-e-text-container">
            <div v-html="pContent" data-slate-editor></div>
          </div>


          <!--左箭头-->
          <div class="titleClass">
            <i class="el-icon-arrow-left" @click="leftClick" v-if="!flagLeft" style="font-size: 30px; cursor: pointer"></i>
            <div class="imgsContainer rowSpaceAround">
              <div class="imgAndName" v-for="(item, index) in imgList" :key="index" @click="imgClick(item, index)">
                <div class="colFlexCenter flexACenterJCenter" style="height: 100%">
                  <img :src="item.url" alt="" class="imgClass" v-show="item.url">
                  <div :class="imgActiveIndex === index ? 'picName' : 'unPicName'">{{item.basicGiName}}</div>
                </div>
                <hr class="bottomLine" v-show="imgActiveIndex === index">
                <!--            <div class="flexACenterJCenter">-->
                <!--              <div class="triangle" v-show="imgActiveIndex === index" ></div>-->
                <!--            </div>-->
              </div>
            </div>
            <!--右箭头-->
            <i class="el-icon-arrow-right" @click="rightClick" v-if="!flagRight" style="font-size: 30px; cursor: pointer"></i>
          </div>



          <el-row>
            <el-col :span="8" class="bottomColClass">
              <img :src="giUrl" alt="" class="giImgClass" v-show="giUrl">
            </el-col>
            <el-col :span="16" class="bottomColClass">
              <div class="giNameClass">{{giName}}</div>
              <div class="w-e-text-container">
                <div v-html="content" data-slate-editor></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import { echoImg } from '@/utils/commonApi'

export default {
  name: 'yellowRiverBasin',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标文化',
        },
        {
          name: '黄河流域',
          path: '/giCulture/yellowRiverBasin'
        },
      ],
      basicArea: '63',
      areaList: [
        { name: '青海省', code: '63', class: 'qinghai' },
        { name: '四川省', code: '51', class: 'sichuan' },
        { name: '甘肃省', code: '62', class: 'gansu' },
        { name: '宁夏回族自治区', code: '64', class: 'ningxia' },
        { name: '内蒙古自治区', code: '15', class: 'neiMonggol' },
        { name: '陕西省', code: '61', class: 'shaanxi' },
        { name: '山西省', code: '14', class: 'shanxi' },
        { name: '河南省', code: '41', class: 'henan' },
        { name: '山东省', code: '37', class: 'shandong' },
      ],
      activeIndex: 0,
      imgActiveIndex: 0,
      imgList: [],
      provinceImgUrl: '', // 省地标图片地址
      provinceName: '', // 省地标协会名称
      giUrl: '', // 地标图片地址
      giName: '', // 地标名称
      content: '', // 地标内容
      pContent: '', // 省地标内容
      numIndex: 4,
      flagLeft: false,
      flagRight: false,
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    /*左箭头点击*/
    leftClick() {
      if (this.numIndex > 4) {
        this.imgList.unshift(this.tabList[this.numIndex - 5]); //前面添加一个
        this.imgList.pop(this.imgList[this.numIndex]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
        this.numIndex--;
        this.imgActiveIndex = 0
        this.giUrl = this.imgList[0].url;
        this.giName = this.imgList[0].basicGiName;
        this.content = this.imgList[0].basicInformation;

        this.$forceUpdate()

      }
    },

    /*右箭头点击*/
    rightClick() {
      if (this.numIndex < this.tabList.length - 1) {
        this.imgList.shift(this.imgList[this.numIndex - 4]); //前面删除一个
        this.imgList.push(this.tabList[this.numIndex + 1]); //后面添加一个
        this.numIndex++;
        this.imgActiveIndex = 0
        this.giUrl = this.imgList[0].url;
        this.giName = this.imgList[0].basicGiName;
        this.content = this.imgList[0].basicInformation;
        this.$forceUpdate()
      }

    },


    changeActiveIndex(val, index) {
      this.activeIndex = index;
      this.basicArea = val.code;
      this.imgList = [];
      this.tabList = [];
      this.giUrl = '';
      this.giName = '';
      this.content = '';
      this.provinceImgUrl = '';
      this.provinceName = '';
      this.pContent = '';
      this.imgActiveIndex = 0;
      this.numIndex = 4;
      this.getData();
    },
    imgClick(val, index) {
      this.imgActiveIndex = index
      this.giUrl = val.url;
      this.giName = val.basicGiName;
      this.content = val.basicInformation;

      this.$forceUpdate()
    },
    getData() {
      let params = {
        basicArea: this.basicArea,
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/nitiativeBasin/yellow",
        data: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          if (res.data.data.data.length > 0) {
            let data = res.data.data.data[0];
            this.provinceImgUrl = data.basicPic ? echoImg(data.basicPic) : '';
            this.provinceName = data.basicAssociation || '';
            this.pContent = data.basicInformation || '';

            if (data.basicList && data.basicList.length > 0) {
              data.basicList.forEach(item => {
                item.url = item.basicPic ? echoImg(item.basicPic) : '';
              })

              if (data.basicList && data.basicList.length > 5) {
                /*tabList接后端返回的数据*/
                this.tabList = data.basicList;
                /*imgList取5个*/
                this.imgList = data.basicList.slice(0, 5);
                this.flagLeft = false;
                this.flagRight = false;
              } else {
                this.imgList = data.basicList ? data.basicList : []
                this.flagLeft = true;
                this.flagRight = true;
              }


              this.giUrl = this.imgList[0].url
              this.giName = this.imgList[0].basicGiName
              this.content = this.imgList[0].basicInformation
              this.$forceUpdate()
            }
          }
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.contentDiv {
  margin: 10px 0 0;
}
.yellowRiverBackground {
  height: 445px;
  width: 100%;
  background-image: url("~@/assets/img/giCulture/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 10px 0 0;

  .areaDot {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    width: 150px;
    height: 20px;
  }

  .imgContainer {
    width: 32px;
    height: 32px;
  }

  .yellowIcon {
    width: 19px;
    height: 19px;
  }
  .blueIcon {
    width: 29px;
    height: 29px;
  }

  // 青海
  .qinghai {
    position: absolute;
    left: 265px;
    top: 270px;

    .onlyFontClass {
      position: absolute;
      top: 15px;
      left: 35px;
    }
  }
  // 四川省
  .sichuan {
    position: absolute;
    left: 344px;
    top: 386px;

    .onlyFontClass {
      position: absolute;
      top: 5px;
      left: 34px;
    }
  }
  // 甘肃省
  .gansu {
    position: absolute;
    left: 403px;
    top: 250px;

    .onlyFontClass {
      position: absolute;
      top: 26px;
      left: 20px;
    }
  }
  // 宁夏回族自治区
  .ningxia {
    position: absolute;
    left: 449px;
    top: 184px;

    .onlyFontClass {
      position: absolute;
      top: -17px;
      left: -110px;
    }
  }
  // 内蒙古自治区
  .neiMonggol {
    position: absolute;
    left: 587px;
    top: 27px;

    .onlyFontClass {
      position: absolute;
      top: 30px;
      left: -40px;
    }
  }
  // 陕西省
  .shaanxi {
    position: absolute;
    left: 650px;
    top: 240px;

    .onlyFontClass {
      position: absolute;
      top: 3px;
      left: -50px;
    }
  }
  // 山西省
  .shanxi {
    position: absolute;
    left: 655px;
    top: 172px;

    .onlyFontClass {
      position: absolute;
      top: 4px;
      left: 35px;
    }
  }
  // 河南省
  .henan {
    position: absolute;
    left: 705px;
    top: 295px;

    .onlyFontClass {
      position: absolute;
      top: 32px;
      left: -5px;
    }
  }
  // 山东省
  .shandong {
    position: absolute;
    left: 895px;
    top: 177px;

    .onlyFontClass {
      position: absolute;
      top: 6px;
      left: 35px;
    }
  }
}

.imgsContainer {
  width: 100%;
  height: 140px;
  margin: 0 0 10px;
  border-bottom: solid 2px #E7ECF0;

  .imgAndName {
    height: 100%;

  }
  .imgClass {
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }
  .picName {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #186CF5;
    line-height: 24px;
    margin: 10px 0 0;
  }
  .unPicName {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin: 10px 0 0;
  }
  .bottomLine {
    width: 100%;
    height: 2px;
    background: #186CF5;
    position: relative;
    top: -8px;
  }
}

.triangle {
  width: 30px;
  height: 30px;
  background: white;
  transform: rotate(45deg);
  position: relative;
  top: -7px;
  box-shadow: 0px 2px 20px 0px rgba(34,80,158,0.06);
}
.bottomRow {
  margin-bottom: 25px;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(34,80,158,0.06);

  .bottomColClass {
    padding: 20px;
  }

  .giImgClass {
    width: 100%;
    height: 100%;
    background: #D8D8D8;
    border-radius: 7px;
  }
  .giNameClass {
    font-size: 28px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
  }
  .giProvinceImgClass {
    width: 50px;
  }
  .giProvinceName {
    font-size: 28px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    margin-left: 10px;
  }
}

.titleClass {
  display: flex;
  align-items: center;
}
</style>
